<template>
  <div class="content">
    <Header :basicinfo="basicinfo" :menuOptions="menuOptions"></Header>
    <router-view :basicinfo="basicinfo"></router-view>
    <Footer :basicinfo="basicinfo" :menuOptions="menuOptions"></Footer>
  </div>
</template>

<script>
import Header from "@/views/components/Header.vue";
import Footer from "@/views/components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      basicinfo: null, //网站基本信息
      // 网站菜单表格数据
      menuList: [],
      // 网站菜单树选项
      menuOptions: [],
    };
  },
  mounted() {
    //获取网站基本信息
    this.getBasicinfo();
    //获取菜单信息
    this.getMenus();
  },
  methods: {
    //获取网站基本信息
    getBasicinfo() {
      var _this = this;
      _this.$axios.get("/system/basicinfo/details/1").then(function (response) {
        if (response.data.code == 200) {
          _this.basicinfo = response.data.data;
        }
      });
    },
    getMenus() {
      var _this = this;
      //ajax获取菜单信息
      _this.$axios
        .get("/system/websitemenu/list", {
          params: {
            status: 0,
          },
        })
        .then(function (response) {
          if (response.data.code == 200) {
            _this.menuList = response.data.data;
            _this.menuOptions = _this.handleTree(_this.menuList, "menuId");
          }
        });
    },
    /**
     * 构造树型结构数据
     * @param {*} data 数据源
     * @param {*} id id字段 默认 'id'
     * @param {*} parentId 父节点字段 默认 'parentId'
     * @param {*} children 孩子节点字段 默认 'children'
     */
    handleTree(data, id, parentId, children) {
      let config = {
        id: id || "id",
        parentId: parentId || "parentId",
        childrenList: children || "children",
      };

      var childrenListMap = {};
      var nodeIds = {};
      var tree = [];

      for (let d of data) {
        let parentId = d[config.parentId];
        if (childrenListMap[parentId] == null) {
          childrenListMap[parentId] = [];
        }
        nodeIds[d[config.id]] = d;
        childrenListMap[parentId].push(d);
      }

      for (let d of data) {
        let parentId = d[config.parentId];
        if (nodeIds[parentId] == null) {
          tree.push(d);
        }
      }

      for (let t of tree) {
        adaptToChildrenList(t);
      }

      function adaptToChildrenList(o) {
        if (childrenListMap[o[config.id]] !== null) {
          o[config.childrenList] = childrenListMap[o[config.id]];
        }
        if (o[config.childrenList]) {
          for (let c of o[config.childrenList]) {
            adaptToChildrenList(c);
          }
        }
      }
      return tree;
    },
  },
};
</script>
<style>
.content {
  background-color: #ffffff;
}
.home {
  margin-top: 60px;
}
</style>
