<template>
  <header>
    <nav
      class="navbar navbar-expand-lg navbar-light"
      style="background-color: #021c11"
    >
      <div class="container">
        <div class="navbar-header">
          <router-link to="/" class="navbar-brand" v-if="basicinfo">
            <img
              class="Brand"
              :alt="basicinfo.corporateAbbr"
              src="@/assets/images/newLogo.png"
            />
          </router-link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="bi bi-list">
            <b-icon icon="list" width="24" height="24"></b-icon>
          </i>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li
              v-for="(menu, index) in menuOptions"
              :key="index"
              class="nav-item"
            >
              <router-link
                :to="menu.path"
                class="nav-link"
                v-if="menu.children == undefined"
                >{{ menu.menuName }}</router-link
              >
              <router-link
                :to="menu.path"
                v-else
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
                >{{ menu.menuName }}</router-link
              >

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdown"
                v-if="menu.children != undefined"
              >
                <router-link
                  :to="children.path"
                  class="dropdown-item"
                  v-for="(children, index) in menu.children"
                  :key="index"
                  >{{ children.menuName }}</router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import $ from "jquery";
export default {
  props: ["basicinfo", "menuOptions"], //basicinfo基本信息
  data() {
    return {
      // 网站菜单表格数据
      // menuList: [],
      // // 网站菜单树选项
      // menuOptions: [],
      chooseMenu: 0,
      fullPath: "",
    };
  },
  computed: {},
  mounted() {
    var _this = this;
    this.fullPath = this.$route.fullPath;
    //ajax获取菜单信息
    // _this.$axios
    //   .get("/system/websitemenu/list", {
    //     params: {
    //       status: 0,
    //     },
    //   })
    //   .then(function (response) {
    //     if (response.data.code == 200) {
    //       _this.menuList = response.data.data;
    //       _this.menuOptions = _this.handleTree(_this.menuList, "menuId");
    //     }
    //   });
  },
  updated: function () {
    this.$nextTick(function () {
      var _this = this;
      //目录选中
      _this.menuChoose();
      //菜单重新渲染后，添加鼠标悬浮事件
      $(".dropdown-menu").parent().addClass("dropdown");
      _this.menuHover();
    });
  },
  methods: {
    //菜单鼠标悬浮事件绑定
    menuHover() {
      //鼠标悬浮打开二级菜单
      $(".dropdown").mouseenter(function () {
        $(this).addClass("show");
        $(this).find(".dropdown-menu").addClass("menu-top show");
      });
      //鼠标离开关闭二级菜单
      $(".dropdown").mouseleave(function () {
        $(this).removeClass("show");
        $(this).find(".dropdown-menu").removeClass("menu-top show");
      });
    },
    menuChoose() {
      var _this = this;
      //移除之前得菜单选中
      $("#navbarSupportedContent").find("li").removeClass("active");
      //目录选中
      var fullPath = "#" + _this.fullPath;
      $("#navbarSupportedContent")
        .find(".navbar-nav")
        .find("a")
        .each(function (index, element) {
          var path = $(element).attr("href");
          if (path == fullPath) {
            $(element).parents("li").addClass("active");
          }
        });
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      document.querySelector("#app").scrollIntoView(true);
      this.fullPath = to.fullPath;
      //目录选中
      this.menuChoose();
    },
  },
};
</script>
<style>
header {
  width: 100%;
  position: fixed;
  display: block;
  z-index: 999;
  top: 0;
}
.dropdown-menu {
  margin-top: 20px !important;
}
.dropdown-item,
.navbar svg {
  font-size: 14px !important;
  color: #f8f9fa !important;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link {
  color: #f8f9fa !important;
}
.navbar-nav {
  line-height: 60px;
}
.dropdown-menu {
  line-height: 28px;
  top: 68% !important;
}
.navbar-light .navbar-nav .active > .nav-link {
  color: #00c9a1 !important;
}
.navbar-nav a {
  color: #f8f9fa !important;
  text-decoration: none !important;
}
.nav-link {
  padding: 0rem 1.5rem !important;
}
.navbar-nav .nav-item a.nav-link:hover,
.dropdown-item:hover,
.navbar-nav a:hover,
.dropdown-menu .dropdown-item:hover > a {
  color: #00c9a1 !important;
}
.navbar-brand {
  padding: 0px !important;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 1.8rem !important;
}
.dropdown-menu {
  background-color: #021c11 !important;
}
.navbar {
  padding: 0px !important;
}
.navbar-brand .Brand {
  height: 48px;
}
</style>
