<template>
  <div class="footer">
    <div class="container footer-nav">
      <div class="row mob-masonry">
        <div
          class="col-lg-3 col-md-3 col-xs-6 list masonry-item"
          v-for="(menu, index) in displayMenu"
          :key="index"
        >
          <h4 class="font-size-16">
            <router-link :to="menu.path" class="nav-link" target="_self">{{
              menu.menuName
            }}</router-link>
          </h4>
          <ul class="ulstyle">
            <li v-for="(children, i) in menu.children" :key="i">
              <router-link
                :to="children.path"
                class="nav-link"
                target="_self"
                >{{ children.menuName }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 col-xs-12 info masonry-item">
          <img src="@/assets/images/QRCode.png" />
          <p>微信扫码添加通讯录</p>
        </div>
      </div>
    </div>
    <!-- 友情链接 -->
    <div class="container" v-if="links">
      <ul class="breadcrumb link-img">
        <li class="breadcrumb-item">友情链接 :</li>
        <li class="breadcrumb-item" v-for="(link, index) in links" :key="index">
          <a
            :href="link.corporateLink"
            :title="link.corporateName"
            target="_blank"
          >
            <span>{{ link.corporateName }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- 公司信息 -->
    <div class="com-info" v-if="basicinfo">
      <span>
        {{ basicinfo.corporateName }} @版权所有 2021-{{ year }}
        <a href="http://beian.miit.gov.cn/" target="_blank">{{
          basicinfo.recordNumber
        }}</a>
      </span>
      <span class="powered_by_metinfo"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["basicinfo", "menuOptions"], //basicinfo基本信息
  data() {
    return {
      links: null,
      displayMenu: [], //显示菜单
      year: 2022,
    };
  },
  created: function () {
    var date = new Date();
    this.year = date.getFullYear();

    //获取页面底部菜单
    this.getBottomMenu();
  },
  computed: {},
  mounted() {
    this.getLinks();
  },
  methods: {
    getLinks() {
      var _this = this;
      //ajax获取友情链接信息
      _this.$axios.get("/system/links/websiteList").then(function (response) {
        if (response.data.code == 200) {
          _this.links = response.data.rows;
        }
      });
    },
    getBottomMenu() {
      var _this = this;
      var menuOptions = _this.menuOptions;
      for (var i = 0; i < menuOptions.length; i++) {
        if (menuOptions[i].children != undefined) {
          _this.displayMenu.push(menuOptions[i]);
        }
      }
    },
  },
  watch: {
    menuOptions(newVal, oldVal) {
      //获取页面底部菜单
      this.getBottomMenu();
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: #021c11;
  color: #aaa;
  text-align: center;
  a:hover {
    text-decoration: none;
    color: #00c9a1 !important;
  }
  .container::after {
    display: table;
    clear: both;
    content: "";
  }
  .com-info {
    border-top: 1px solid #222624;
    padding: 15px 0px;
    a {
      color: #aaa;
    }
    a:hover {
      text-decoration: none;
      color: #00c9a1 !important;
    }
  }
}
.footer-nav {
  padding: 20px 0px;
  border-bottom: 1px solid #222624;
  h4 a {
    color: #ffffff;
  }
  .ulstyle {
    padding: 0px !important;
  }
  .ulstyle li {
    padding-bottom: 5px;
  }
  .ulstyle li a {
    color: #aaa;
  }
  .masonry-item em a {
    color: #00c9a1 !important;
  }
}
.breadcrumb {
  display: inline-flex !important;
  background-color: #021c11 !important;
  margin-bottom: 0px !important;
  a {
    color: #aaa;
    text-decoration: none;
  }
}
.breadcrumb-item::before {
  content: "" !important;
}
.info img {
  width: 100px;
}
.info p {
  line-height: 1.6rem;
  margin-bottom: 0rem;
}
</style>
